import {
  mdiCloudUpload,
  mdiViewDashboard,
  mdiAccountGroup,
  mdiBullseyeArrow,
  mdiFileDocumentOutline,
  mdiPencilRuler,
  mdiAccount,
} from '@mdi/js'

export default [
  { header: 'Seiten' },
  { title: 'Dashboard', to: { name: 'dashboard' }, icon: mdiViewDashboard },
  { title: 'Kampagne', to: { name: 'kampagne' }, icon: mdiAccountGroup },
  { title: 'Kampagne erstellen', to: { name: 'kampagne_erstellen' }, icon: mdiAccountGroup },
  { title: 'Zielgruppe', to: { name: 'zielgruppe' }, icon: mdiBullseyeArrow },
  { title: 'Zielgruppe erstellen', to: { name: 'zielgruppe_erstellen' }, icon: mdiBullseyeArrow },
  { title: 'Zielgruppe hochladen', to: { name: 'zielgruppe_hochladen' }, icon: mdiCloudUpload },
  { title: 'Rechnung', to: { name: 'rechnung' }, icon: mdiFileDocumentOutline },
  { title: 'Werbemittel', to: { name: 'werbemittel' }, icon: mdiPencilRuler },
  { title: 'Werbemittel erstellen', to: { name: 'werbemittel_erstellen' }, icon: mdiPencilRuler },
  { title: 'Werbemittel hochladen', to: { name: 'werbemittel_hochladen' }, icon: mdiCloudUpload },
  { title: 'Konto', to: { name: 'konto' }, icon: mdiAccount },
]
