import {
  mdiAccountGroup,
  mdiBullseyeArrow,
  mdiPencilRuler,
} from '@mdi/js'

export default [
  {
    title: 'Zielgruppen',
    icon: mdiBullseyeArrow  ,
    to: 'zielgruppe',
  },

  {
    title: 'Werbemittel',
    icon: mdiPencilRuler,
    to: 'werbemittel',
  },
  {
    title: 'Mailingkampagnen',
    icon: mdiAccountGroup  ,
    to: 'kampagne',
  },
]

