const postkartenTool = [
  {
    path: '/login',
    name: 'auth-login',
    props:true,
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
      layout: 'content',
    },
  },

  {
    path: '/kampagne',
    name: 'kampagne',
    props: true,
    component: () => import('@/views/campaign/CampaignListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/kampagne/erstellen',
    name: 'kampagne_erstellen',
    props: true,
    component: () => import('@/views/campaign/CampaignView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/kampagne/editieren/:idPtKampagne(.*?)',
    name: 'kampagne_editieren',
    props: true,
    component: () => import('@/views/campaign/CampaignView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/kampagne/statistiken',
    name: 'kampagne_statistiken',
    props: true,
    component: () => import('@/views/campaignStatistics/CampaignStatisticsView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/kampagne/freigeben/:hashKey(.*?)',
    name: 'kampagne_freigeben',
    props:true,
    component: () => import('@/views/campaign/CampaignReleaseView'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

  {
    path: '/zielgruppe',
    name: 'zielgruppe',
    component: () => import('@/views/targetGroup/TargetGroupListView'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/zielgruppe/erstellen',
    name: 'zielgruppe_erstellen',
    props: true,
    component: () => import('@/views/targetGroup/TargetGroupView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/zielgruppe/hochladen',
    name: 'zielgruppe_hochladen',
    props: true,
    component: () => import('@/views/targetGroup/TargetGroupUploadView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/zielgruppe/editieren/:idPtZielgruppe(.*?)',
    name: 'zielgruppe_editieren',
    props: true,
    component: () => import('@/views/targetGroup/TargetGroupView'),
    meta: {
      layout: 'content',
    },
  },


  {
    path: '/rechnung',
    name: 'rechnung',
    component: () => import('@/views/invoice/InvoiceListView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/werbemittel',
    name: 'werbemittel',
    component: () => import('@/views/adMaterial/AdMaterialListView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/werbemittel/erstellen',
    name: 'werbemittel_erstellen',
    props: true,
    component: () => import('@/views/adMaterial/AdMaterialView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/werbemittel/hochladen',
    name: 'werbemittel_hochladen',
    props: true,
    component: () => import('@/views/adMaterial/AdMaterialUploadView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/werbemittel/hochladen/:idPtWerbemittel(.*?)',
    name: 'werbemittel_hochladen',
    props: true,
    component: () => import('@/views/adMaterial/AdMaterialUploadView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/werbemittel/editieren/:idPtWerbemittel(.*?)',
    name: 'werbemittel_editieren',
    props: true,
    component: () => import('@/views/adMaterial/AdMaterialView'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/passwort_vergessen',
    name: 'passwort_vergessen',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/passwort_zuruecksetzen/:hashKey(.*?)',
    name: 'passwort_zuruecksetzen',
    props: true,
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/passwort_neu/:hashKey(.*?)',
    name: 'passwort_neu',
    props: true,
    component: () => import('@/views/NewPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/entsperrung/:hashKey(.*?)',
    name: 'entsperrung',
    props: true,
    component: () => import('@/views/UnlockLogin.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/aktivierung/:hashKey(.*?)',
    name: 'aktivierung',
    props: true,
    component: () => import('@/views/Activation.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/konto',
    name: 'konto',
    component: () => import('@/views/Account.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/mitarbeiter',
    name: 'mitarbeiter',
    component: () => import('@/views/EmployeeListView.vue'),
    meta: {
      layout: 'content',
    },
  },


  {
    path: '/mitarbeiter/editieren/:idThHeadhunterMitarbeiter(.*?)',
    name: 'mitarbeiter_editieren',
    props: true,
    component: () => import('@/views/EmployeeView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/mitarbeiter/erstellen',
    name: 'mitarbeiter_erstellen',
    props: true,
    component: () => import('@/views/EmployeeView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apizugang',
    name: 'apizugang',
    component: () => import('@/views/apiaccess/ApiAccessListView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default postkartenTool;
